import React, { useRef } from 'react'
import Arrow from 'icons/Arrow'
import THEME from 'utils/theme'
import { useInterval } from 'hooks'

function ImageCarousel({
  images = [],
  shadow = true,
  width = '100%',
  maxWidth = '100%',
  rotateInterval = 0,
  continuous = false
}) {
  const imageReel = useRef(continuous ? [...images, images[0]] : images)
  const carouselRef = useRef()

  function handlePrevious() {
    const currentScrollPosition = carouselRef.current.scrollLeft
    const carouselWidth = carouselRef.current.clientWidth
    if (currentScrollPosition === 0) {
      carouselRef.current.scrollTo({ left: (images.length - 1) * carouselWidth, behavior: 'smooth' })
    } else {
      carouselRef.current.scrollTo({ left: currentScrollPosition - carouselWidth, behavior: 'smooth' })
    }
  }

  function handleNext() {
    const currentScrollPosition = carouselRef.current.scrollLeft
    const carouselWidth = carouselRef.current.clientWidth
    const currentIndex = Math.round(currentScrollPosition / carouselWidth)
    if (!continuous && currentIndex === images.length - 1) {
      carouselRef.current.scrollTo(0, 0)
    } else if (continuous && currentIndex === images.length) {
      carouselRef.current.scrollTo(0, 0)
      setTimeout(handleNext, 0)
    } else {
      carouselRef.current.scrollTo(currentScrollPosition + carouselWidth, 0)
    }
  }

  useInterval(handleNext, rotateInterval)

  return (
    <>
      <div className='shadow-box'>
        <div className='relative-box'>
          <div className='carousel-container' ref={carouselRef}>
            {imageReel.current.map(({ src, destination }, index) => (
              <div key={index} className='carousel-item'>
                {destination ? (
                  <a href={destination} target='blank' rel='noopener noreferrer'>
                    <img src={src} alt={`image-${index}`} id={`carousel-item-${index}`} />
                  </a>
                ) : (
                  <img src={src} alt={`image-${index}`} id={`carousel-item-${index}`} />
                )}
              </div>
            ))}
          </div>
          {!rotateInterval && (
            <>
              <span className='arrowNav leftArrow' onClick={handlePrevious}>
                <Arrow height='22px' width='22px' direction='left' color='#FFFFFF' />
              </span>
              <span className='arrowNav rightArrow' onClick={handleNext}>
                <Arrow height='22px' width='22px' direction='right' color='#FFFFFF' />
              </span>
            </>
          )}
        </div>
      </div>
      <style jsx>{`
        .shadow-box {
          padding: ${!rotateInterval ? '0.75rem 1.2rem' : '0'};
          border-radius: 6px;
          box-shadow: ${shadow ? `2px 3px 8px ${THEME.COLORS.GRAY}` : 'none'};
        }
        .relative-box {
          position: relative;
        }
        .carousel-container {
          display: flex;
          width: ${width};
          max-width: ${maxWidth};
          height: auto;
          overflow-x: ${!rotateInterval ? 'scroll' : 'hidden'};
          -webkit-overflow-scrolling: auto;
          scroll-snap-type: x mandatory;
        }
        .carousel-item {
          width: 100%;
          flex: 0 0 100%;
          height: auto;
          scroll-snap-align: start;
        }
        .carousel-item img {
          width: 100%;
          height: auto;
        }
        .arrowNav {
          height: 30px;
          width: 30px;
          padding: 4px;
          background: rgba(0, 0, 0, 0.8);
          border-radius: 50%;
          position: absolute;
          top: calc(50% - 15px);
          cursor: pointer;
          align-items: center;
          justify-content: center;
        }
        .leftArrow {
          left: -1rem;
          padding: 4px 6px 4px 2px;
        }
        .rightArrow {
          right: -1rem;
          padding: 4px 2px 4px 6px;
        }
      `}</style>
    </>
  )
}

export default ImageCarousel
