import React, { forwardRef, useMemo, useCallback, useState } from 'react'
import { observer } from 'mobx-react-lite'
import PropTypes from 'prop-types'
import MuxPlayer from '@mux/mux-player-react'

import { useStore } from 'hooks'
import { AuthStore } from 'stores/auth'
import { track } from '../../lib/whoami'
import { videoEvents } from 'lib/ga'

const VideoPlayer = observer(
  forwardRef(({ src, autoplay = false, liveui = false, poster, muxData, onEnded, onTimeUpdate }, ref) => {
    const authStore = useStore(AuthStore)
    const [playing, setPlaying] = useState(false)

    const playerSrcProps = useMemo(() => {
      const url = new URL(src)

      let props = {}
      if (url.hostname === 'stream.mux.com') {
        props.playbackId = url.pathname.slice(1).split('.')[0]
      } else {
        props.src = url.href
      }

      if (url.hostname === 'stream.mux.com' && url.searchParams.has('token')) {
        props.tokens = {
          playback: url.searchParams.get('token')
        }
      }

      return props
    }, [src])

    function handleOnPlay() {
      setPlaying(true)
      videoEvents.play()
      track('ContentStarted', {
        content: {
          type: 'Video',
          title: muxData?.video_title,
          src
        }
      })
    }

    function handleOnPause() {
      setPlaying(false)
      videoEvents.pause()
    }

    function handleOnEnded() {
      setPlaying(false)
      videoEvents.end()
      track('ContentCompleted', {
        content: {
          type: 'Video',
          title: muxData?.video_title,
          src
        }
      })
      onEnded?.()
    }

    return (
      <div>
        <MuxPlayer
          ref={ref}
          envKey={process.env.MUX_DATA_KEY}
          autoPlay={autoplay ? 'any' : false}
          streamType={liveui ? 'live' : 'on-demand'}
          defaultHiddenCaptions={true}
          poster={poster}
          onPlay={handleOnPlay}
          onPause={handleOnPause}
          onEnded={handleOnEnded}
          onTimeUpdate={(e) => onTimeUpdate?.(e)}
          metadata={{
            viewer_user_id: String(authStore.userId),
            player_name: 'RNR Web',
            ...muxData
          }}
          style={{
            '--seek-backward-button': playing ? undefined : 'none',
            '--seek-forward-button': playing ? undefined : 'none',
            '--top-controls': playing ? undefined : 'none',
            '--bottom-controls': playing ? undefined : 'none'
          }}
          {...playerSrcProps}
        />
      </div>
    )
  })
)

export default VideoPlayer

VideoPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  autoplay: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  preload: PropTypes.string,
  liveui: PropTypes.bool,
  poster: PropTypes.string,
  muxData: PropTypes.object
}
