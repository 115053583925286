import React from 'react'
import { observer } from 'mobx-react-lite'

import EventLink from 'components/EventLink'
import VideoPlayer from 'components/VideoPlayer'
import EmailSubscribeForm from 'components/EmailSubscribeForm'
import GifToVideo from 'components/GifToVideo'
import Checklist from 'components/Checklist'
import ImageCarousel from 'components/ImageCarousel'
import MediaQuery from 'components/MediaQuery'
import EventDetailActionPanel from 'components/EventDetailActionPanel'

import THEME from 'utils/theme'

const DEFAULT_PROMO_SOURCE = {
  src: 'https://stream.mux.com/CjzrVrSUOiKyj02k02RyXa9LI4hRLrKiY1.m3u8',
  type: 'application/x-mpegURL'
}

const DEFAULT_PROMO_THUMBNAIL =
  'https://chumley.barstoolsports.com/union/2020/10/01/upcoming-thumbnail.12c17108.png.e30cfe94.jpeg?crop=0.92%2C0.92%2Cx0.03%2Cy0.03%2Csafe'

const DEFAULT_PROMO_POSTER =
  'https://chumley.barstoolsports.com/union/2020/10/01/upcoming-thumbnail.12c17108.png.e30cfe94.jpeg?crop=0.92%2C0.92%2Cx0.03%2Cy0.03%2Csafe'

const DEFAULT_GIFS = [
  'https://chumley.barstoolsports.com/union/2020/10/14/giphy-1.598b32a4.gif',
  'https://chumley.barstoolsports.com/union/2021/03/03/fatguys.778fcab6.gif',
  'https://chumley.barstoolsports.com/union/2020/10/14/giphy-3.1cf1ad21.gif',
  'https://chumley.barstoolsports.com/union/2020/10/14/giphy-2.c9ada4e1.gif'
]

const Home = observer(({ event, forceWinterized = false }) => {
  const promoVideoSource = event?.metadata?.promo_video?.source ?? DEFAULT_PROMO_SOURCE
  const promoPosterUrl = event?.metadata?.promo_poster_url ?? DEFAULT_PROMO_POSTER
  const taleOfTapeImages = event?.metadata?.tale_of_tape_images?.map((src) => ({ src }))
  const logoUrl = event?.metadata?.logo_url ?? '/static/images/logo.svg'

  const promoGifs = event?.metadata?.promo_gifs?.length > 0 ? event?.metadata?.promo_gifs : DEFAULT_GIFS

  return (
    <>
      <section className='hero' data-testid='Home'>
        <div className='container'>
          <div className='row row--centered'>
            <div className='col col--video'>
              <div className='video'>
                <VideoPlayer
                  src={promoVideoSource?.src}
                  poster={event?.metadata?.promo_video?.thumbnail ?? DEFAULT_PROMO_THUMBNAIL}
                  muxData={{ video_title: event ? `${event.name} | Home Promo` : 'Fallback Promo | Home Promo' }}
                />
              </div>
            </div>
            <div className='col col--cta'>
              {event && !forceWinterized ? (
                <EventDetailActionPanel event={event} respectCountdownOption={true} />
              ) : (
                <div className='panel panel--white'>
                  <h2 className='panel__title'>Get Updated on Upcoming Rough N' Rowdy Events</h2>
                  <Checklist>
                    <li>
                      Fighters with no defense throwing haymakers in the most action packed fighting event you'll ever
                      have.
                    </li>
                    <li>Laugh-out-loud non-stop commentary from Dave "El Pres" Portnoy &amp; Dan "Big Cat" Katz.</li>
                    <li>Insane unpredictable action in the ring.</li>
                  </Checklist>
                  <EmailSubscribeForm />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      {event && !forceWinterized && (
        <>
          <section className='section section--gray'>
            <div className='container'>
              <div className='row row--reversed-mobile'>
                <div className='col col--section'>
                  <h2 className='section__title'>{event?.metadata?.supporting_copy?.headline}</h2>
                  {event?.metadata?.supporting_copy?.list?.map((text, index) => (
                    <p key={index}>{text}</p>
                  ))}
                  <p className='disclaimer'>
                    {event?.metadata?.supporting_copy?.disclaimer ?? 'Fight card subject to change.'}
                  </p>
                  <p>
                    <EventLink className='btn btn--primary' event={event} location='event-section' />
                  </p>
                </div>
                {taleOfTapeImages && taleOfTapeImages?.length > 0 ? (
                  <>
                    <MediaQuery maxWidth={THEME.BREAKPOINTS.MD - 1}>
                      {taleOfTapeImages?.length && (
                        <div className='col col--section promo-poster'>
                          <ImageCarousel images={taleOfTapeImages} />
                        </div>
                      )}
                    </MediaQuery>
                    <MediaQuery minWidth={THEME.BREAKPOINTS.MD}>
                      {promoPosterUrl ? (
                        <div className='col col--section promo-poster'>
                          <img src={event?.metadata?.promo_poster_url} alt='Rough N Rowdy' />
                        </div>
                      ) : (
                        <div className='col col--section promo-poster'>
                          <div className='placeholder-container'>
                            <img className='promo-poster-placeholder' src={logoUrl} alt='Rough N Rowdy' />
                          </div>
                        </div>
                      )}
                    </MediaQuery>
                  </>
                ) : (
                  <div className='col col--section promo-poster'>
                    {promoPosterUrl ? (
                      <img src={event?.metadata?.promo_poster_url} alt='Rough N Rowdy' />
                    ) : (
                      <div className='placeholder-container'>
                        <img className='promo-poster-placeholder' src={logoUrl} alt='Rough N Rowdy' />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </section>
          {(event?.metadata?.mobile_ring_girl_image || event?.metadata?.desktop_ring_girl_image) && (
            <div className='section--gray ring-girl-image-wrapper'>
              {event?.metadata?.mobile_ring_girl_image && (
                <img
                  className='ring-girl-image-mobile'
                  src={event?.metadata?.mobile_ring_girl_image}
                  alt='mobile-ring-girl-img'
                />
              )}
              {event?.metadata?.desktop_ring_girl_image && (
                <img
                  className='ring-girl-image-desktop'
                  src={event?.metadata?.desktop_ring_girl_image}
                  alt='desktop-ring-girl-img'
                />
              )}
            </div>
          )}
          <section className='section'>
            <div className='container'>
              <div className='row'>
                <div className='col col--section'>
                  <div className='video'>
                    <VideoPlayer
                      src={
                        event.metadata.viewer_feedback_video?.source?.src ??
                        'https://stream.mux.com/9VCrq6kBrU1kZO7Y54Y6SCGbI02bRXEyJ.m3u8'
                      }
                      poster={
                        event.metadata.viewer_feedback_video?.thumbnail ??
                        'https://chumley.barstoolsports.com/union/2020/09/14/thumbnail.0a64b7ca.jpg'
                      }
                      muxData={{
                        video_title: 'RNR Home Promo'
                      }}
                    />
                  </div>
                  <br />
                </div>
                <div className='col col--section'>
                  <h2 className='section__title textCenter'>Feedback from past purchasers</h2>
                  <div className='row'>
                    <div className='col'>
                      <p className='quote'>This is 50x better than actual pay per views</p>
                    </div>
                    <div className='col'>
                      <p className='quote'>This is the greatest thing barstool sports has ever done</p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col'>
                      <p className='quote quote--lg'>Holy shit this is unreal content</p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col'>
                      <p className='quote'>This is the content that made barstool great in the first place</p>
                    </div>
                    <div className='col'>
                      <p className='quote'>Best commentary since vince mcmahon in the early 90s</p>
                    </div>
                  </div>
                  <p>
                    <EventLink className='btn btn--primary' event={event} location='testimonials-section' />
                  </p>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
      <section className='section section--gray'>
        <div className='container'>
          <div className='row'>
            {promoGifs.map((url) => (
              <div key={url} className='col col--gif'>
                <GifToVideo src={url} />
              </div>
            ))}
          </div>
        </div>
      </section>
      <style global jsx>{`
        .siteNavigation__upcoming-events {
          display: none !important;
        }
        .video {
          position: relative;
        }
        .placeholder-container {
          width: 100%;
          position: relative;
          height: 0;
          padding-bottom: 100%;
          background-color: #000;
          display: flex;
        }
        .promo-poster-placeholder {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding-left: 10px;
          padding-right: 10px;
        }
        .row {
          align-items: center;
        }
        .hero {
          background: #000;
          padding: 20px 0 0;
        }
        .hero .container {
          padding-left: 0;
          padding-right: 0;
        }
        .hero .col {
          flex-basis: 100%;
          min-width: 100%;
        }
        .section {
          background: #fff;
          padding: 50px 0;
        }
        .section--gray {
          background: #f7f7f7;
        }
        .col--section {
          flex-basis: 100%;
          min-width: 100%;
        }
        .col--section {
          padding: 10px 15px;
        }
        .col--gif {
          padding: 5px 10px;
          flex-basis: 50%;
          max-width: 50%;
        }
        .ring-girl-image-wrapper {
          padding-bottom: 30px;
        }
        .ring-girl-image-mobile {
          margin: auto;
          width: 100%;
          max-width: 500px;
          display: block;
        }
        .ring-girl-image-desktop {
          display: none;
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .col--gif {
            flex-basis: 25%;
            max-width: 25%;
          }
        }
        @media (max-width: ${THEME.BREAKPOINTS.MD}px) {
          .row--reversed-mobile {
            flex-direction: column-reverse;
          }
        }
        @media (max-width: ${THEME.BREAKPOINTS.MD}px) {
          .section {
            padding: 15px 0;
          }
        }
        .section__title {
          font-family: ${THEME.FONTS.BEBAS};
          font-size: 32px;
          font-weight: normal;
          letter-spacing: 0.99px;
          line-height: 36px;
          margin: 0 0 30px;
        }
        .section p {
          font-size: 16px;
          line-height: 20px;
        }
        .section p + p {
          margin: 20px 0 0;
        }
        .disclaimer {
          font-style: italic;
          letter-spacing: 0.2px;
          font-size: 13px;
          line-height: 15px;
          text-align: center;
        }
        .quote {
          position: relative;
          text-transform: uppercase;
          font-size: 15px;
          letter-spacing: 1.5px;
          line-height: 17px;
          padding-left: 25px;
          margin: 0 0 40px;
        }
        .quote--lg {
          display: table;
          margin-left: auto;
          margin-right: auto;
          font-size: 18px;
          letter-spacing: 2px;
          line-height: 22px;
          max-width: 220px;
        }
        .quote:before {
          content: '“';
          font-size: 35px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 43px;
          position: absolute;
          top: -15px;
          left: 0;
        }
        .panel {
          margin: auto;
          padding: 20px 40px;
          font-weight: normal;
        }
        .panel--white {
          background: #fff;
          box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.3);
        }
        @media (max-width: ${THEME.BREAKPOINTS.SM}px) {
          .panel,
          .panel--white {
            padding: 10px 15px;
          }
        }
        .panel__title {
          font-family: ${THEME.FONTS.BEBAS};
          font-size: 30px;
          font-weight: normal;
          letter-spacing: 0.65px;
          line-height: 30px;
          text-align: center;
          margin: 0 0 12px;
        }
        .panel__date {
          font-family: ${THEME.FONTS.BEBAS};
          font-size: 50px;
          line-height: 1;
          letter-spacing: 0;
          text-align: center;
          margin: 0;
          margin-left: -50px; /* hack to make sure text stays centered even if overflowing container a bit */
          margin-right: -50px;
          text-align: center;
          white-space: nowrap;
        }
        @media (max-width: 400px) {
          .panel__title {
            font-size: 24px;
          }
          .panel__date {
            font-size: 40px;
          }
        }
        .promo-banner {
          display: flex;
          background-color: #000;
          width: 100%;
          height: 100%;
        }
        .app-icons-container {
          display: flex;
          justify-content: center;
          padding-top: 18px;
          width: 100%;
        }
        .app-icons {
          width: 100%;
          max-width: 600px;
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .quote--lg {
            max-width: 100%;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
          .hero {
            padding: 20px 0 40px;
          }
          .hero .container {
            padding-left: 15px;
            padding-right: 15px;
          }
          .hero .col--video {
            flex-basis: 55%;
            min-width: 55%;
          }
          .hero .col--cta {
            flex-basis: 45%;
            min-width: 45%;
          }
          .panel {
            padding: 20px;
            max-width: 500px;
          }
          .panel__date {
            font-size: 50px;
            line-height: 69px;
          }
          .col--section {
            flex-basis: 50%;
            min-width: 50%;
          }
          .promo-poster img {
            box-shadow: 2px 4px 10px ${THEME.COLORS.GRAY};
          }
          .ring-girl-image-wrapper {
            padding: 0 30px 30px;
          }
          .ring-girl-image-mobile {
            display: none;
          }
          .ring-girl-image-desktop {
            display: block;
            margin: auto;
            width: 90%;
            max-width: 1200px;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.LG}px) {
          .panel {
            padding: 20px 40px;
          }
          .hero .col--video {
            flex-basis: 55%;
            min-width: 55%;
          }
          .hero .col--cta {
            flex-basis: 45%;
            min-width: 45%;
          }
        }
      `}</style>
    </>
  )
})

export default Home
