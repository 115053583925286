import React, { useEffect } from 'react'

import Head from 'components/Head'
import SiteLayout from 'components/SiteLayout'
import Home from 'components/Home'

import { EventsStore } from 'stores/events'
import { useStore, usePageView } from 'hooks'

const HomePage = ({ events = [] }) => {
  const event = events?.[0]
  useStore(EventsStore, { items: events, current: event })
  usePageView()

  return (
    <>
      <SiteLayout>
        <Head
          title="Rough N' Rowdy | RnR"
          description="Rough N' Rowdy Brawl is fighters with no defense throwing haymakers. It's the funniest most action packed night you'll ever have. Join us for our next event."
          noIndexNoFollow
        />
        <Home events={events} event={event} forceWinterized={process.env.WINTERIZED} />
      </SiteLayout>
    </>
  )
}

export async function getStaticProps() {
  const events = await EventsStore().findRNR()
  return {
    props: { events },
    revalidate: 1
  }
}

export default HomePage
